import React, { createContext, ReactElement, useContext, useState } from 'react';

interface IBlogContext {
    typeId: number | null,
    setTypeId: React.Dispatch<React.SetStateAction<number | null>> | (() => void)
}

/* eslint-disable @typescript-eslint/no-empty-function */
const blogContext = createContext<IBlogContext>({
    typeId   : null,
    setTypeId: () => {}
});

const { Provider } = blogContext;

const BlogProvider = ({ children }: { children: ReactElement }) => {
    const [typeId, setTypeId] = useState<number | null>(null);

    return (
        <Provider value={{
            typeId,
            setTypeId
        }}
        >
            {children}
        </Provider>);
};

const useBlogContext = () => useContext(blogContext);

export { blogContext, useBlogContext, BlogProvider };
