import React, { useEffect, useLayoutEffect, useState } from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { IBlogPage } from '../../types/strapi/blogPage';
import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { IPostType, TBlogPost } from '../../types/strapi/posts';
import Button from '../button-like/button';
import Footer from '../footer';
import GridWrapper from '../grid-wrapper';
import Layout from '../layout';
import PageSection from '../PageSection';

import BlogArticles from './components/BlogArticles';
// import BlogBanner from './components/BlogBanner';
import BlogMenu from './components/BlogMenu';
import { useBlogContext } from './context';


import './index.css';

interface IQueryData extends IGlobalServerRuntimeData {
    blogPage: IBlogPage,
    posts: Array<TBlogPost>,
    postTypes: Array<IPostType>,
    postsCount: number,
    tag: number | null
}

interface IProps {
    data: IQueryData
}

function getDefaultTheme(): boolean {
    const theme = window.localStorage.getItem('theme');
    return theme === 'dark';
}

const BlogPage = ({ data }: IProps) => {
    const seo = {
        metaTitle      : data.blogPage.seo.metaTitle,
        metaDescription: data.blogPage.seo.metaDescription
    };
    const cn = useClassnames();

    const [shadow, setShadow] = useState(false);
    const [showLoadButton, setShowLoadButton] = useState(false);

    const rootClassName = 'blog-page';
    const wrapper = `${rootClassName}__wrapper ${shadow ? `${rootClassName}__wrapper_shadow` : ''}`;

    const firstNewsBlockSize = 4;
    const [posts, setPosts] = useState(data.posts);
    let firstNewsBlock = posts.slice(0, firstNewsBlockSize);
    let secondNewsBlock = posts.slice(firstNewsBlockSize);

    const [postsCount, setCount] = useState(data.postsCount);
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    const { typeId } = useBlogContext();

    const tag = data.tag;

    const loadMore = async () => {
        const type = typeId ? `&type=${typeId}` : '';
        const from = posts.length;
        const tagParam = tag ? `&tag=${tag}` : '';
        const response = await fetch(
            `/api/blog?from=${from}${type}&limit=6${tagParam}`
        );

        const data = await response.json();

        if(data?.data?.posts) {
            setPosts([...posts, ...data.data.posts]);
            setCount(data.postsCount);
        }
    };

    const loadByType = async () => {
        if(typeId) {
            const tagParam = tag ? `&tag=${tag}` : '';
            const response = await fetch(
                `/api/blog?from=0&type=${typeId}&limit=7${tagParam}`
            );

            const data = await response.json();

            if(data?.data?.posts) {
                setPosts(data.data.posts);
                setCount(data.data.postsCount);
            }
        } else {
            setCount(data.postsCount);
            setPosts(data.posts);
        }
    };

    useEffect(() => {
        firstNewsBlock = posts.slice(0, firstNewsBlockSize);
        secondNewsBlock = posts.slice(firstNewsBlockSize);
    }, [posts]);

    useEffect(() => {
        setShowLoadButton(postsCount > (firstNewsBlock.length + secondNewsBlock.length));
    }, [postsCount]);

    useEffect(() => {
        void loadByType();
    }, [typeId]);

    useEffect(() => {
        if(isDarkTheme) {
            document.querySelector('.app-layout')?.classList.add('dark');
        } else {
            document.querySelector('.app-layout')?.classList.remove('dark');
        }
        window.localStorage.setItem('theme', isDarkTheme ? 'dark' : 'light');
    }, [isDarkTheme]);

    useLayoutEffect(() => {
        const isDark = getDefaultTheme();

        setIsDarkTheme(isDark);

        if(isDark) {
            document.querySelector('.app-layout')?.classList.add('dark');
        }
    }, [setIsDarkTheme]);

    return (
        <Layout
            seo={seo}
            hasContactButton={true}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
            isDarkTheme={isDarkTheme}
        >
            <PageSection
                removeBelowOffset={true} className={cn(`${rootClassName}__header`)}
            >
                <BlogMenu
                    func={setShadow}
                    types={data.postTypes}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                />
            </PageSection>
            <PageSection className={cn(`${rootClassName}__articles ${wrapper}`)}>
                <BlogArticles items={firstNewsBlock} main={true} />
            </PageSection>
            {/* <PageSection className={cn(`${rootClassName}__popular ${wrapper}`)}>*/}
            {/*    <BlogArticles items={data.posts} carousel={true} head={true} />*/}
            {/* </PageSection>*/}
            {/* <PageSection className={cn(`${rootClassName}__news ${wrapper}`)}>*/}
            {/*    <BlogBanner />*/}
            {/* </PageSection>*/}
            <PageSection className={cn(`${rootClassName}__news ${wrapper}`)}>
                <BlogArticles items={secondNewsBlock} />
            </PageSection>
            {showLoadButton && (
                <GridWrapper className={`${rootClassName}__more`}>
                    <Button
                        onClick={loadMore}
                        className={`${rootClassName}__button-more`}
                    >
                        Показать еще
                    </Button>
                </GridWrapper>
            )}
            <Footer
                header="Заполните форму, чтобы познакомиться с нами поближе или задать вопрос!"
                className={`${wrapper}`} data={data.footer}
            />
        </Layout>
    );
};


export default BlogPage;
