import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IPostType } from '../../../../types/strapi/posts';
import GridWrapper from '../../../grid-wrapper';
import Heading from '../../../heading';
import Text from '../../../text';
import { useBlogContext } from '../../context';

import Arrow from './assets/arrow-down.inline.svg';
import Search from './assets/search.inline.svg';
import Theme from './assets/theme.inline.svg';

import './index.css';


const rootClassName = 'blog-menu';

const allButtonValue = 'all';

const allItem: Pick<IPostType, 'pluralText' | 'value'> = {
    pluralText: 'Все',
    value     : allButtonValue
};

interface IProps {
    func: React.Dispatch<React.SetStateAction<boolean>>,
    types: Array<IPostType>,
    setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>,
    isDarkTheme: boolean
}

const headerText = 'Блог';

const BlogMenu = ({ func, types, setIsDarkTheme, isDarkTheme }: IProps) => {
    const cn = useClassnames();
    const [searchOpen, setSearchOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selected, setSelected] = useState(allItem);
    const { setTypeId } = useBlogContext();

    const [listActive, setListActive] = useState(false);
    const [posts, setPosts] = useState([]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    let debounceTimer: NodeJS.Timeout;
    const debounceTime = 500;

    useEffect(() => {
        func(searchOpen || menuOpen);
    }, [menuOpen, searchOpen]);

    useEffect(() => {
        if(inputRef?.current?.value && posts.length !== 0 && searchOpen) {
            setListActive(true);
        } else {
            setListActive(false);
        }
    }, [searchOpen]);

    const list: Array<IPostType | Pick<IPostType, 'pluralText' | 'value'>> = [allItem, ...types];

    const onSearch = () => {
        clearTimeout(debounceTimer);

        if(inputRef?.current?.value) {
            debounceTimer = setTimeout(() => {
                void fetch(
                    `/api/blog?from=0&term=${inputRef?.current?.value}&limit=3`
                ).then(async (res) => {
                    const data = await res.json();
                    if(data?.data?.posts) {
                        setPosts(data.data.posts);
                        setListActive(true);
                    }
                });
            }, debounceTime);
        } else {
            setListActive(false);
        }
    };

    return (
        <GridWrapper
            className={cn(rootClassName)} onClick={(e) => {
                const className = e.target.className;
                if(!searchOpen) {
                    return;
                }

                if(
                    typeof className.indexOf !== 'undefined'
                    && (className.indexOf('blog-menu__search-input') !== -1 || className.indexOf('blog-menu__search-clear') !== -1)
                ) {
                    return;
                }
                setSearchOpen(false);
            }}
        >
            <Heading className={`${rootClassName}__title`} level={1} as="h1">{headerText}</Heading>
            <div className={`${rootClassName}__search ${searchOpen ? `${rootClassName}__search_open` : ''}`}>
                <label className={`${rootClassName}__search-label`}>
                    <input
                        className={`${rootClassName}__search-input`} ref={inputRef} placeholder="Что хотите найти?"
                        onInput={onSearch}
                    />
                    <button
                        className={`${rootClassName}__search-clear ${inputRef?.current?.value ? `${rootClassName}__search-clear_view` : ''}`}
                        onClick={() => {
                            if(inputRef?.current) {
                                inputRef.current.value = '';
                            }
                            onSearch();
                        }}
                    >Очистить
                    </button>
                </label>
                <CSSTransition
                    in={listActive} timeout={{ enter: 300, exit: 300 }} classNames={`${rootClassName}__search-list`}
                    unmountOnExit={true} mountOnEnter={true}
                >
                    <ul className={`${rootClassName}__search-list`} >
                        {posts.length ? posts.map(({ title, id }, index) => {
                            return (
                                <li key={`search-item-${index}`} className={`${rootClassName}__search-item`}>
                                    <a href={`${id ? `/news/${id}` : '#'}`}>
                                        <Text
                                            className={cn(`${rootClassName}__search-item-text`)}
                                            size={3}
                                            dangerouslySetInnerHTML={{ __html: title }}
                                        />
                                    </a>
                                </li>
                            );
                        }) : (
                            <div>
                                Ничего не найдено. Пожалуйста, введите другой запрос для поиска.
                            </div>
                        )}
                    </ul>
                </CSSTransition>
            </div>
            <div className={`${rootClassName}__menu`}>
                <button
                    className={`${rootClassName}__menu-btn`} onClick={() => {
                        setMenuOpen(!menuOpen);
                        setSearchOpen(false);
                    }}
                >
                    <Text
                        className={cn(`${rootClassName}__item-text`)}
                        size={2}
                        dangerouslySetInnerHTML={{ __html: selected?.pluralText }}
                    />
                    <Arrow />
                </button>
                <ul className={`${rootClassName}__list ${menuOpen ? `${rootClassName}__list_open` : ''}`}>
                    {list.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={`${rootClassName}__item ${item.value === selected.value ? `${rootClassName}__item_active` : ''}`}
                                onClick={() => {
                                    setTypeId('id' in item ? item.id : null);
                                    setSelected(item);
                                    setMenuOpen(false);
                                }}
                            >
                                <Text
                                    className={cn(`${rootClassName}__item-text`)}
                                    size={2}
                                    dangerouslySetInnerHTML={{ __html: item.pluralText }}
                                />
                            </li>
                        );
                    })}
                </ul>
                <div className={`${rootClassName}__buttons`}>
                    <button
                        className={`${rootClassName}__button ${rootClassName}__button_search`} onClick={() => {
                            setSearchOpen(!searchOpen);
                            setMenuOpen(false);

                            if(inputRef?.current) {
                                inputRef.current.focus();
                            }
                        }}
                    ><Search />
                    </button>
                </div>
                <button
                    className={`${rootClassName}__button ${rootClassName}__button_theme`}
                    onClick={() => {
                        setIsDarkTheme(!isDarkTheme);
                    }}
                ><Theme />
                </button>
            </div>
        </GridWrapper>
    );
};

export default BlogMenu;
